@import "fonts";
@import "colors";
@import '~@angular/cdk/overlay-prebuilt.css';

*, *:before, *:after {
  padding: 0;
  margin: 0;
  border: 0;
}

html, body {
  height: 100%;
}

body {
  font-family: $main-font-family;
  font-size: $main-font-size;
  background-color: $background-color;
}

a {
  text-decoration: none;
}
li {
  list-style-type: none;
}

img {
  max-width: 100%;
  height: auto;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.dialog-backdrop {
    background: $black-color;
    opacity: 0.7 !important;
  }
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.loader-screen-backdrop {
    background: $black-color;
    opacity: 0.7 !important;
  }
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.dialog-backdrop {
    background: $black-color;
    opacity: 0.7 !important;
  }
}

.warning-donation-lowered-popup-container{
  .mat-mdc-dialog-surface{
    background: red !important;
    border-radius: 32px !important;;
  }
}


@font-face {
  font-family: GilroyRegular;
  src: url('/assets/fonts/gilroy-regular.ttf') format('truetype');
}

@font-face {
  font-family: GilroyBold;
  src: url('/assets/fonts/gilroy-bold.ttf') format('truetype');
}

@font-face {
  font-family: GilroyMedium;
  src: url('/assets/fonts/gilroy-medium.ttf') format('truetype');
}

@font-face {
  font-family: GilroySemibold;
  src: url('/assets/fonts/gilroy-semibold.ttf') format('truetype');
}
