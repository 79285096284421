//obsolete
$white-color: #ffffff;
$black-color: #202020;
$aqua-color: #00D2E7;
$dark-blue-color: #163fa7;
$aqua2-color: #A7D7DD;
$grey-color: #A6B2B5;
$light-grey-color: #F4F9FA;
$light-aqua-color: #CEF0F4;
$light-aqua2-color: #E4F3F7;
$red-color: #FF0022;
$green-color: #6BBD6E;
$yellow-color: #FFAA2C;
$super-light-aqua-color: #F9FCFC;
$opacity-aqua-color: #F4F9FA;

//new
$midnight:#222648;
$deep-blush:#f5559d;
$transparency-deep-blush: rgba(229, 85, 158, 0.1);
$bright-turquoise:#12c2e9;
$transparency-bright-turquoise: rgba(18, 194, 233, 0.1);
$selago:#eef0fd;
$banana-mania:#fcefbf;
$lavender:#c471ed;
$black:#161616;
$gray:#acb9bc;
$light-gray:#f8f8f8;
$white:#fff;
$red:#f84565;
$yellow:#ffea2d;
$green:#49d8ad;

$background-color: $white;
