$regular: "GilroyRegular";
$bold: "GilroyBold";
$medium: "GilroyMedium";
$semi-bold: "GilroySemibold";

$main-font-family: $regular;
$main-font-size: 16px;
$header-big-size: 30px;
$header-medium-size: 22px;
$header-small-size: 20px;

@mixin linearTextGradient($color1, $color2, $startPoint:left top, $endPoint:right bottom)
{
  color: mix($color1, $color2);
  background: -webkit-gradient(linear, $startPoint, $endPoint, from($color1), to($color2));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px #00000000;
}

.heading1{
  font-size: 32px;
  font-family: $bold;
}
.heading2{
  font-size: 28px;
  font-family: $bold;
}
.heading3{
  font-size: 24px;
  font-family: $bold;
}
.heading4{
  font-size: 18px;
  font-family: $bold;
}
.heading5{
  font-size: 16px;
  font-family: $bold;
}
.body-large{
  font-size: 20px;
  font-family: $regular;
}
.body-regular{
  font-size: 16px;
  font-family: $regular;
}
.body-medium{
  font-size: 16px;
  font-family: $medium;
}
.body-small{
  font-size: 14px;
  &-regular{
    font-family: $regular;
  }
  &-medium{
    font-family: $medium;
  }
}
.metadata14{
  font-size: 14px;
  &-regular{
    font-family: $regular;
  }
  &-medium{
    font-family: $medium;
  }
  &-semi-bold{
    font-family: $semi-bold;
  }
}
.metadata12{
  font-size: 12px;
  &-regular{
    font-family: $regular;
  }
  &-medium{
    font-family: $medium;
  }
  &-semi-bold{
    font-family: $semi-bold;
  }
}
